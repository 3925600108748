import {
  Button,
  Card,
  CardContent,
  Icon,
  Stack,
  Typography
} from '@mui/material'
import { Form } from 'react-router-dom'

export default function Subscription() {
  return (
    <Stack spacing={2}>
      <Typography textAlign="center" variant="body1">
        Pick a subscription plan to get started.
      </Typography>
      <Card elevation={6}>
        <CardContent>
          <Form method="post">
            <Stack spacing={2} sx={{ justifyContent: 'center' }}>
              <Typography component="h2" textAlign="center" variant="h5">
                Yearly Plan
              </Typography>
              <div>
                <Typography textAlign="center" variant="h6">
                  <s><i>$23.88</i></s>
                </Typography>
                <Typography textAlign="center" variant="h3">
                  $19.99
                </Typography>
                <Typography textAlign="center" variant="h5">
                  per year
                </Typography>
                <Typography textAlign="center" variant="body1">
                  <b><i>Only $1.67 per month!</i></b>
                </Typography>
              </div>
              <Button
                disableElevation
                name="intent"
                type="submit"
                value="checkout"
                variant="contained"
              >
                Subscribe now
              </Button>
            </Stack>
            <input name="plan" value="yearly" type="hidden" />
          </Form>
        </CardContent>
      </Card>
      <Card variant="outlined">
        <CardContent>
          <Form method="post">
            <Stack spacing={2} sx={{ justifyContent: 'center' }}>
              <Typography component="h2" textAlign="center" variant="h5">
                Monthly Plan
              </Typography>
              <div>
                <Typography textAlign="center" variant="h3">
                  $1.99
                </Typography>
                <Typography textAlign="center" variant="h5">
                  per month
                </Typography>
              </div>
              <Button
                disableElevation
                name="intent"
                type="submit"
                value="checkout"
                variant="contained"
              >
                Subscribe now
              </Button>
            </Stack>
            <input name="plan" value="monthly" type="hidden" />
          </Form>
        </CardContent>
      </Card>
      <Form action="/" method="POST">
        <Button
          component="button"
          fullWidth
          name="intent"
          startIcon={<Icon>logout</Icon>}
          type="submit"
          value="logout"
        >
          Log out
        </Button>
      </Form>
    </Stack>
  )
}
