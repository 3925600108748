import { Box, Container, Typography } from '@mui/material'
import { useRouteError } from 'react-router-dom'

export default function Error() {
  const error = useRouteError()
  console.error(error)

  return (
    <Container maxWidth="sm">
      <Box py={4}>
        <Typography variant="h3" textAlign="center">
          Oops!
        </Typography>
        <Typography variant="body1" textAlign="center">
          {error.statusText || error.message}
        </Typography>
      </Box>
    </Container>
  )
}
