import {
  Alert,
  Button,
  Icon,
  Link as MuiLink,
  Stack,
  Typography
} from '@mui/material'
import {
  Form,
  json,
  Link,
  redirect,
  useActionData,
  useLoaderData
} from 'react-router-dom'
import { apiDelete, apiGet, apiPost, Endpoints } from '../lib/api'
import Notification from './notification'

export async function notificationsLoader() {
  const response = await apiGet(Endpoints.API_USER_NOTIFICATION)

  if (response.status !== 200) {
    throw response
  }

  const json = await response.json()
  return json.notification
}

export async function notificationsAction({ request }) {
  const formData = await request.formData()
  const intent = formData.get('intent')

  if (intent === 'add') {
    return json({})
  }

  if (intent === 'save') {
    const payload = Object.fromEntries(formData)
    return await apiPost(Endpoints.API_USER_NOTIFICATION, payload)
  }

  if (intent === 'delete') {
    await apiDelete(Endpoints.API_USER_NOTIFICATION)
    return redirect('/notifications')
  }

  throw json(
    { message: 'Invalid intent' },
    { status: 400 }
  )
}

export default function Notifications() {
  const notification = useLoaderData()
  const newNotification = useActionData()

  return (
    <Stack spacing={2}>
      {(notification || newNotification) &&
        <Notification notification={notification ?? newNotification} />
      }
      {!notification && !newNotification &&
        <Alert severity="info">
          Add a notification to start receiving your Daily Bread.
        </Alert>
      }
      {!notification && !newNotification &&
        <Form method="POST">
          <Button
            disableElevation
            fullWidth
            name="intent"
            startIcon={<Icon>add</Icon>}
            type="submit"
            value="add"
            variant="contained"
          >
            Add notification
          </Button>
        </Form>
      }
      <Typography sx={{ color: '#666', fontSize: '0.75rem' }} variant="body2">
        <sup>*</sup>
        {' '}
        By saving this notification, you consent to receive daily text messages with your account balances from Daily Bread. Consent is not a condition of purchase. Message and data rates may apply. To stop receiving text messages, reply "stop" via SMS or delete this notification from your Daily Bread account.
        {' '}
        <MuiLink component={Link} to="/privacy">
          Privacy
        </MuiLink>
        {' & '}
        <MuiLink component={Link} to="/terms">
          Terms
        </MuiLink>
      </Typography>
    </Stack>
  )
}
