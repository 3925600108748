import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material'
import {
  Form,
  redirect,
  useActionData,
  useRouteLoaderData
} from 'react-router-dom'
import { apiDelete, apiPost, Endpoints } from '../lib/api'

const subscriptionStatuses = {
  'active': {
    icon: 'check_circle_outline',
    severity: 'success'
  },
  'ending': {
    icon: 'error_outline',
    severity: 'warning'
  },
  'inactive': {
    icon: 'highlight_off',
    severity: 'error'
  }
}

export async function settingsAction({ request }) {
  const formData = await request.formData()
  const intent = formData.get('intent')

  if (intent === 'save') {
    const payload = Object.fromEntries(formData)
    return await apiPost(Endpoints.API_USER, payload)
  }

  if (intent === 'manage') {
    const response = await apiPost(Endpoints.API_USER_SUBSCRIPTION_MANAGE)
    const json = await response.json()
    window.location.assign(json.sessionUrl)
    return true
  }

  if (intent === 'delete') {
    await apiDelete(Endpoints.API_USER)
    return redirect('/login')
  }
}

export default function Settings() {
  const user = useRouteLoaderData('home')
  const data = useActionData()
  let phone = ''

  // Strip country code from phone number
  if (user?.phone) {
    phone = user.phone.substring(2)
  }

  const subStatus = subscriptionStatuses[user.subscription_status] ??
    subscriptionStatuses['inactive']

  return (
    <Form method="POST">
      <Stack spacing={2}>
        <Card variant="outlined">
          <CardHeader title="Settings" />
          <CardContent>
            <Stack spacing={2}>
              <TextField
                defaultValue={user?.email}
                disabled
                fullWidth
                label="Email"
                type="email"
              />
              <TextField
                autoComplete="name"
                defaultValue={user?.name}
                error={data?.error?.field === 'name'}
                fullWidth
                helperText={data?.error?.field === 'name' ? data.error.message : null}
                label="Name"
                name="name"
                required
              />
              <TextField
                autoComplete="tel-national"
                defaultValue={phone}
                error={data?.error?.field === 'phone'}
                fullWidth
                helperText={data?.error?.field === 'phone' ? data.error.message : null}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+1</InputAdornment>
                }}
                label="Phone"
                name="phone"
                required
              />
            </Stack>
          </CardContent>
          <CardActions>
            <span style={{ flexGrow: 1 }} />
            <IconButton
              color="success"
              name="intent"
              type="submit"
              value="save"
            >
              <Icon>check_circle</Icon>
            </IconButton>
          </CardActions>
        </Card>
        <Alert
          action={
            <IconButton
              color="info"
              name="intent"
              type="submit"
              value="manage"
            >
              <Icon>build_circle</Icon>
            </IconButton>
          }
          icon={<Icon>{subStatus.icon}</Icon>}
          severity={subStatus.severity}
          sx={{ '& .MuiAlert-action': { paddingTop: 0 } }}
        >
          Your subscription is {user.subscription_status}
          {user.subscription_ending && ' on ' + user.subscription_ending}
        </Alert>
        <Button
          color="error"
          disableElevation
          name="intent"
          startIcon={<Icon>remove_circle</Icon>}
          type="submit"
          value="delete"
          variant="outlined"
        >
          Delete account
        </Button>
      </Stack>
    </Form>
  )
}
