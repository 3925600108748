import { Link, Stack, Typography } from '@mui/material'

export default function Privacy() {
  return (
    <Stack spacing={2}>
      <Typography component="h2" variant="h5">
        Privacy policy
      </Typography>
      <Typography variant="body1">
        Daily Bread values your privacy. By using Daily Bread in any way, including only browisng the site, you are agreeing to be bound by our policies. Daily Bread may change these policies at any time.
      </Typography>
      <Typography component="h3" variant="h6">
        What information we collect
      </Typography>
      <Typography variant="body1">
        Daily Bread only collects your account information, your bank account connection information, and your notification configuration. Your account information includes your email, name, phone number, password, and any other information we request to successfully administer your account. Your bank account connection information is provided via Plaid, and no sensitive information such as passwords or account numbers are received or stored by Daily Bread. Your notification configuration includes notification medium and time of day to send those notifications. 
      </Typography>
      <Typography component="h3" variant="h6">
        How we use your information
      </Typography>
      <Typography variant="body1">
        Daily Bread only uses your information to provide and improve our services. We do not sell your information. We do not share your information with third parties except as necessary to provide our services. We may disclose your information to comply with legal processes.
      </Typography>
      <Typography component="h3" variant="h6">
        Opt-out
      </Typography>
      <Typography variant="body1">
        Daily Bread allows you to opt out of SMS communications by deleting your notification configurations through the Daily Bread website or by replying "stop" via SMS.
      </Typography>
      <Typography component="h3" variant="h6">
        Children
      </Typography>
      <Typography variant="body1">
        Daily Bread does not allow user account for or knowingly collect information from individuals under the age of 16.
      </Typography>
      <Typography component="h3" variant="h6">
        Security
      </Typography>
      <Typography variant="body1">
        Daily Bread works hard to limit the amount of sensitive information required to provide our services and to store and transfer any sensitive information we do collect in as securely as possible.
      </Typography>
      <Typography component="h3" variant="h6">
        Contact
      </Typography>
      <Typography variant="body1">
        Any questions or requests related to this policy may be sent by email to <Link href="mailto:help@farhner.solutions">help@farhner.solutions</Link>.
      </Typography>
    </Stack>
  )
}
