import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { Form, json, redirect, useFetcher } from 'react-router-dom'
import { apiDelete, apiGet, apiPut, Endpoints } from '../lib/api'
import LoadingOverlay from './loading-overlay'

export async function accountAction({ params, request }) {
  const formData = await request.formData()
  const intent = formData.get('intent')
  const endpoint = Endpoints.API_ACCOUNTS_ID.replace(':id', params.itemId)

  if (intent === 'save') {
    const payload = Object.fromEntries(formData)
    const response = await apiPut(endpoint, payload)
  
    if (response.status !== 200) {
      throw response
    }
  
    return redirect('/accounts')
  }

  if (intent === 'delete') {
    const response = await apiDelete(endpoint)

    if (response.status !== 200) {
      throw response
    }
  
    return redirect('/accounts')
  }

  throw json(
    { message: 'Invalid intent' },
    { status: 400 }
  )
}

export default function Account(props) {
  const { item } = props
  const fetcher = useFetcher()
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(false)

  const onSuccess = useCallback(() => {
    localStorage.removeItem('plaidLinkToken')
    fetcher.load()
  }, [fetcher])

  const createLinkToken = useCallback(async () => {
    setLoading(true)

    // For OAuth, use previously generated Link token
    if (window.location.href.includes("?oauth_state_id=")) {
      const linkToken = localStorage.getItem('plaidLinkToken')
      setToken(linkToken)
    } else {
      const endpoint = Endpoints.API_ACCOUNTS_LINKTOKEN_ID
        .replace(':id', item.id)
      const response = await apiGet(endpoint)
      const json = await response.json()
      localStorage.setItem('plaidLinkToken', json.link_token)
      setToken(json.link_token)
    }
  }, [])

  let isOauth = false

  const config = {
    token,
    onSuccess,
  }

  // For OAuth, configure the received redirect URI
  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href
    isOauth = true
  }

  const { open, ready } = usePlaidLink(config)

  useEffect(() => {
    if (token && ready) {
      open()
      setLoading(false)
    }
  }, [createLinkToken, token, isOauth, ready, open])

  return (
    <>
      <Form action={`${item.id}`} method="POST">
        <Card variant="outlined">
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: item.color }} src={item.logo}>
                {item.logo ? null : item.name.substring(0, 1)}
              </Avatar>
            }
            title={item.name}
          />
          <CardContent>
            <Stack spacing={2}>
              <TextField
                fullWidth
                label="Bank nickname"
                name="nickname"
                defaultValue={item.nickname ?? item.name}
              />
              <List dense disablePadding>
                {item.accounts.map(account => {
                  const balance = new Intl.NumberFormat('en-US',
                    { style: 'currency', currency: 'USD' })
                    .format(account.balance)

                  return (
                    <ListItem
                      disableGutters
                      key={`${item.name}_${account.name}`}
                    >
                      <ListItemText primary={account.name} />
                      <Typography variant="body2">
                        {balance}
                      </Typography>
                    </ListItem>
                  )
                })}
              </List>
            </Stack>
          </CardContent>
          <CardActions>
            <span style={{ flexGrow: 1 }} />
            <IconButton
              color="error"
              name="intent"
              type="submit"
              value="delete"
            >
              <Icon>remove_circle</Icon>
            </IconButton>
            <IconButton color="info" onClick={createLinkToken}>
              <Icon>build_circle</Icon>
            </IconButton>
            <IconButton
              color="success"
              name="intent"
              type="submit"
              value="save"
            >
              <Icon>check_circle</Icon>
            </IconButton>
          </CardActions>
        </Card>
      </Form>
      {loading && <LoadingOverlay />}
    </>
  )
}
