import { Alert, Button, Icon, Stack } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useFetcher, useLoaderData } from 'react-router-dom'
import { Endpoints, apiGet, apiPost } from '../lib/api'
import Account from './account'
import LoadingOverlay from './loading-overlay'

export async function accountsLoader() {
  const response = await apiGet(Endpoints.API_ACCOUNTS)

  if (response.status !== 200) {
    throw response
  }

  const json = await response.json()
  return json.items
}

export async function accountsAction() {
  return true
}

export default function Accounts() {
  const items = useLoaderData()
  const fetcher = useFetcher()
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(false)

  const onSuccess = useCallback(async publicToken => {
    setLoading(true)
    await apiPost(Endpoints.API_ACCOUNTS_PUBLICTOKEN, { publicToken })
    localStorage.removeItem('plaidLinkToken')
    fetcher.submit(null, { method: 'POST', action: '' })
  }, [fetcher])

  const createLinkToken = useCallback(async () => {
    setLoading(true)

    // For OAuth, use previously generated Link token
    if (window.location.href.includes("?oauth_state_id=")) {
      const linkToken = localStorage.getItem('plaidLinkToken')
      setToken(linkToken)
    } else {
      const response = await apiGet(Endpoints.API_ACCOUNTS_LINKTOKEN)
      const json = await response.json()
      localStorage.setItem('plaidLinkToken', json.link_token)
      setToken(json.link_token)
    }
  }, [])

  let isOauth = false

  const config = {
    token,
    onSuccess,
  }

  // For OAuth, configure the received redirect URI
  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href
    isOauth = true
  }

  const { open, ready } = usePlaidLink(config)

  useEffect(() => {
    if (fetcher.data || fetcher.state === 'idle') {
      setLoading(false)
    }
  }, [fetcher.data, fetcher.state])

  useEffect(() => {
    if (token && ready) {
      open()
      setLoading(false)
    }
  }, [token, ready, open])

  return (
    <>
      <Stack spacing={2}>
        {items &&
          items.map(item => (
            <Account item={item} key={item.name} />
          ))
        }
        {!(items?.length > 0) &&
          <Alert severity="info">
            Add a bank account to start receiving your Daily Bread.
          </Alert>
        }
        <Button
          disableElevation
          onClick={createLinkToken}
          startIcon={<Icon>add</Icon>}
          sx={{ margin: '0 auto' }}
          variant="contained"
        >
          Add bank account
        </Button>
      </Stack>
      {(loading || fetcher.state !== 'idle') && <LoadingOverlay />}
    </>
  )
}
