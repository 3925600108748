import { Alert, Icon, Stack } from '@mui/material'
import { Link, useRouteLoaderData } from 'react-router-dom'

export default function Instructions() {
  const user = useRouteLoaderData('home')

  return (
    <Stack spacing={2}>
      {user.hasAccount
        ? <Alert icon={<Icon>check_circle_outline</Icon>} severity="success">
            Your bank account is connected.
          </Alert>
        : <Alert icon={<Icon>radio_button_unchecked</Icon>} severity="warning">
            <Link to="/accounts">Connect your bank account</Link> to Daily Bread.
          </Alert>
      }
      {user.hasNotification
        ? <Alert icon={<Icon>check_circle_outline</Icon>} severity="success">
            Your notification is set up.
          </Alert>
        : <Alert icon={<Icon>radio_button_unchecked</Icon>} severity="warning">
            <Link to="/notifications">Set up a notification</Link> to receive a text message every day at a certain time.
          </Alert>
      }
      {user.hasAccount && user.hasNotification
        ? <Alert icon={<Icon>check_circle_outline</Icon>} severity="success">
            Daily Bread sends you a notification every day with your account balances.
          </Alert>
        : <Alert icon={<Icon>radio_button_unchecked</Icon>} severity="warning">
            Daily Bread can't send you a notification every day with your account balances.
          </Alert>
      }
    </Stack>
  )
}
