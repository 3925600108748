import {
  Chip,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import {
  Form,
  NavLink,
  Outlet,
  redirect,
  useLoaderData
} from 'react-router-dom'
import { Endpoints, apiGet, apiPost } from '../lib/api'
import Subscription from './subscription'

export async function homeLoader() {
  const response = await apiGet(Endpoints.API_USER)
  const json = await response.json()

  if (!json.user) {
    return redirect('/about')
  }

  return json.user
}

export async function homeAction({ request }) {
  const formData = await request.formData()
  const intent = formData.get('intent')

  if (intent === 'logout') {
    await apiPost(Endpoints.AUTH_LOGOUT)
    return redirect('/login')
  }

  if (intent === 'checkout') {
    const payload = Object.fromEntries(formData)
    const response = await apiPost(Endpoints.API_USER_SUBSCRIPTION, payload)
    const json = await response.json()
    window.location.assign(json.sessionUrl)
    return true
  }
}

export default function Home() {
  const user = useLoaderData()

  if (!user) {
    return null
  }

  return (
    <Stack spacing={2}>
      <Typography component="h2" variant="h3" textAlign="center">
        Hi, {user.name}!
      </Typography>
      {user.subscription_active &&
        <>
          <Paper variant="outlined">
            <Form method="POST">
              <List dense>
                <ListItem disablePadding>
                  <NavLink to="accounts">
                    <ListItemButton>
                      <ListItemIcon>
                        <Icon>account_balance</Icon>
                      </ListItemIcon>
                      <ListItemText primary="Bank accounts" />
                      {user.hasAccount
                        ? <ListItemSecondaryAction>
                            {user.accounts === 1
                              ? <Icon color="success">
                                  check_circle_outline
                                </Icon>
                              : <Chip
                                  label={user.accounts}
                                  color="success"
                                  size="small"
                                  sx={{
                                    border: '2px solid rgb(46, 125, 50)',
                                    fontWeight: 'bold',
                                    height: '20.5px',
                                    marginRight: '2.5px',
                                    '& .MuiChip-label': {
                                      paddingLeft: '4.5px',
                                      paddingRight: '4px'
                                    }
                                  }}
                                  variant="outlined"
                                />
                            }
                          </ListItemSecondaryAction>
                        : <ListItemSecondaryAction>
                            <Icon color="warning">error_outline</Icon>
                          </ListItemSecondaryAction>
                      }
                    </ListItemButton>
                  </NavLink>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={NavLink} to="notifications">
                    <ListItemIcon>
                      <Icon>notifications_active</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Notifications" />
                    {user.hasNotification
                      ? <ListItemSecondaryAction>
                          <Icon color="success">check_circle_outline</Icon>
                        </ListItemSecondaryAction>
                      : <ListItemSecondaryAction>
                          <Icon color="warning">error_outline</Icon>
                        </ListItemSecondaryAction>
                    }
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={NavLink} to="settings">
                    <ListItemIcon>
                      <Icon>settings</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                    {user.subscription_active
                      ? <ListItemSecondaryAction>
                          <Icon color="success">check_circle_outline</Icon>
                        </ListItemSecondaryAction>
                      : <ListItemSecondaryAction>
                          <Icon color="warning">error_outline</Icon>
                        </ListItemSecondaryAction>
                    }
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component="button"
                    name="intent"
                    type="submit"
                    value="logout"
                  >
                    <ListItemIcon>
                      <Icon>logout</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Log out" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Form>
          </Paper>
          <Outlet />
        </>
      }
      {!user.subscription_active &&
        <Subscription />
      }
    </Stack>
  )
}
