import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Login, { loginAction } from './components/login'
import Error from './components/error'
import Signup, { signupAction } from './components/signup'
import Home, { homeAction, homeLoader } from './components/home'
import Accounts, { accountsAction, accountsLoader } from './components/accounts'
import Notifications, { notificationsAction, notificationsLoader } from './components/notifications'
import Settings, { settingsAction } from './components/settings'
import { accountAction } from './components/account'
import About from './components/about'
import Instructions from './components/instructions'
import Privacy from './components/privacy'
import Terms from './components/terms'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: <Home />,
        id: 'home',
        loader: homeLoader,
        action: homeAction,
        children: [
          {
            path: '/',
            action: homeAction,
            element: <Instructions />
          },
          {
            path: 'accounts',
            element: <Accounts />,
            loader: accountsLoader,
            action: accountsAction,
            children: [
              {
                path: ':itemId',
                action: accountAction
              }
            ]
          },
          {
            path: 'notifications',
            element: <Notifications />,
            loader: notificationsLoader,
            action: notificationsAction
          },
          {
            path: 'settings',
            element: <Settings />,
            action: settingsAction
          }
        ]
      },
      {
        path: 'login',
        element: <Login />,
        action: loginAction
      },
      {
        path: 'signup',
        element: <Signup />,
        action: signupAction
      },
      {
        path: 'about',
        element: <About />
      },
      {
        path: 'privacy',
        element: <Privacy />
      },
      {
        path: 'terms',
        element: <Terms />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
