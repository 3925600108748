function apiFetch(method, endpoint, body) {
  return fetch(process.env.REACT_APP_API_URL + endpoint, {
    method,
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(body)
  })
}

export async function apiGet(endpoint) {
  return apiFetch('GET', endpoint)
}

export async function apiPost(endpoint, body) {
  return apiFetch('POST', endpoint, body)
}

export async function apiPut(endpoint, body) {
  return apiFetch('PUT', endpoint, body)
}

export async function apiDelete(endpoint, body) {
  return apiFetch('DELETE', endpoint, body)
}

export const Endpoints = Object.freeze({
  API_ACCOUNTS_ID: '/api/accounts/:id',
  API_ACCOUNTS_LINKTOKEN: '/api/accounts/linkToken',
  API_ACCOUNTS_LINKTOKEN_ID: '/api/accounts/linkToken/:id',
  API_ACCOUNTS_PUBLICTOKEN: '/api/accounts/publicToken',
  API_ACCOUNTS: '/api/accounts',
  API_USER: '/api/user',
  API_USER_NOTIFICATION: '/api/user/notification',
  API_USER_SUBSCRIPTION: '/api/user/subscription',
  API_USER_SUBSCRIPTION_MANAGE: '/api/user/subscription/manage',
  AUTH_LOGIN: '/auth/login',
  AUTH_LOGOUT: '/auth/logout',
  AUTH_SIGNUP: '/auth/signup'
})
