import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  Stack
} from '@mui/material'
import { MobileTimePicker } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import utc from 'dayjs/plugin/utc'
import { Form } from 'react-router-dom'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

// Get the current time rounded to the next 5 minute increment
const defaultTime = () => dayjs()
  .minute(Math.ceil(dayjs().minute() / 5) * 5)
  .second(0)

export default function Notification(props) {
  const { notification } = props
  const [medium, setMedium] = useState('text')
  const [time, setTime] = useState(defaultTime())

  useEffect(() => {
    setMedium(notification.medium ?? 'text')
    setTime(notification.send_at
      ? dayjs(notification.send_at)
      : defaultTime())
  }, [notification])

  return (
    <Form method="POST">
      <Card variant="outlined">
        <CardHeader title="Notification" />
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              {/** @todo support email */}
              {/* <TextField
                defaultValue="text"
                label="How"
                name="medium"
                onChange={(e) => setMedium(e.target.value)}
                select
                value={medium}
              >
                <MenuItem key="text" value="text">Text</MenuItem>
                <MenuItem key="email" value="email">Email</MenuItem>
              </TextField> */}
              <span>
                Text
                <sup style={{ color: '#666' }}>*</sup>
                {' '}
                me daily at
              </span>
              <MobileTimePicker
                label="When"
                minutesStep={5}
                onChange={setTime}
                sx={{ width: 100 }}
                value={time}
              />
              <input
                name="send_at"
                type="hidden"
                value={time.second(0).format('HH:mm:ssZ')}
              />
            </Stack>
          </Stack>
          <input name="medium" type="hidden" value="text" />
        </CardContent>
        <CardActions>
          <span style={{ flexGrow: 1 }} />
          {notification?.id &&
            <IconButton
              color="error"
              name="intent"
              type="submit"
              value="delete"
            >
              <Icon>remove_circle</Icon>
            </IconButton>
          }
          <IconButton
            color="success"
            name="intent"
            type="submit"
            value="save"
          >
            <Icon>check_circle</Icon>
          </IconButton>
        </CardActions>
      </Card>
    </Form>
  )
}