import { Button, Icon, Stack, TextField } from '@mui/material'
import { Form, Link, redirect, useActionData } from 'react-router-dom'
import { Endpoints, apiPost } from '../lib/api'
import Teaser from './teaser'

export async function loginAction({ request }) {
  const formData = await request.formData()
  const payload = Object.fromEntries(formData)
  const response = await apiPost(Endpoints.AUTH_LOGIN, payload)

  if (response.status !== 200) {
    if (response.status === 401) {
      return true
    }

    throw response
  }

  return redirect('/')
}

export default function Login() {
  const error = useActionData()

  return (
    <Stack spacing={4}>
      <Teaser />
      <Form method="post">
        <Stack spacing={2}>
          <TextField
            autoComplete="email"
            error={error}
            fullWidth
            helperText={error && 'Wrong email or password'}
            label="Email"
            name="email"
            required
            type="email"
          />
          <TextField
            autoComplete="current-password"
            error={error}
            fullWidth
            label="Password"
            name="password"
            required
            type="password"
          />
          <Button
            disableElevation
            startIcon={<Icon>login</Icon>}
            type="submit"
            variant="contained"
          >
            Log in
          </Button>
          <Button
            component={Link}
            disableElevation
            fullWidth
            startIcon={<Icon>account_circle</Icon>}
            to="/signup"
          >
            Sign up
          </Button>
        </Stack>
      </Form>
    </Stack>
  )
}
