import { Link, Stack, Typography } from '@mui/material'

export default function Terms() {
  return (
    <Stack spacing={2}>
      <Typography component="h2" variant="h5">
        Terms of use
      </Typography>
      <Typography variant="body1">
        These terms of use explain the agreement you make with Daily Bread. By using Daily Bread in any way, including only browsing the site, you are agreeing to be bound by these terms. Daily Bread may change these terms at any time.
      </Typography>
      <Typography component="h3" variant="h6">
        Summary of services
      </Typography>
      <Typography variant="body1">
        Daily Bread provides daily email and SMS notifications to users with their financial account balances. Financial account information is obtained via third-party services, and Daily Bread has limited access to sensitive information about the user's financial accounts. To provide this service, Daily Bread charges a monthly or yearly subscription fee to the user. The user is responsible for payment of the subscription fee as well as any applicable message or data rates for receiving daily SMS messages from Daily Bread.
      </Typography>
      <Typography variant="body1">
        The user is exclusively responsible and liable for use of the services and any consequences of their use. Any exceptions to this will be outlined in these terms. By using the services, you agree that Daily Bread will not be responsible or liable to you or any third party for any consequence, loss, or damage related to using the services, unless specifically described otherwise in these terms.
      </Typography>
      <Typography component="h3" variant="h6">
        Our rights as Daily Bread
      </Typography>
      <Typography variant="body1">
        Daily Bread reserves the right to refuse to provide all or any part of the services and to terminate any user, all at any time and for any reason, without liability to you.
      </Typography>
      <Typography component="h3" variant="h6">
        Contact
      </Typography>
      <Typography variant="body1">
        Any questions or requests related to these terms may be sent by email to <Link href="mailto:help@farhner.solutions">help@farhner.solutions</Link>.
      </Typography>
    </Stack>
  )
}
