import { Button, Icon, Link as MuiLink, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export default function About() {
  return (
    <Stack spacing={2}>
      <Button
        component={Link}
        disableElevation
        startIcon={<Icon>account_circle</Icon>}
        to="/signup"
        variant="contained"
      >
        Sign up
      </Button>
      <Button
        component={Link}
        disableElevation
        fullWidth
        startIcon={<Icon>login</Icon>}
        to="/login"
      >
        Log in
      </Button>
      <Typography variant="body1">
        Daily Bread sends you your bank account balances every day. That's it.
      </Typography>
      <img
        alt="Daily Bread SMS message sample"
        src="daily_bread_sms.png"
        style={{ width: '100%', height: 'auto', paddingBottom: 32, paddingTop: 16 }}
      />
      <Typography component="h2" variant="h5">
        Why?
      </Typography>
      <Typography variant="body1">
        Knowing where your finances are is critical to sticking to a budget. Think of Daily Bread's notifications like the equivalent of looking in your wallet to see if you have enough cash.
      </Typography>
      <Typography component="h2" variant="h5">
        How does it work?
      </Typography>
      <Typography variant="body1">
        <ol style={{ marginBottom: 0, marginTop: 0 }}>
          <li>You connect your bank accounts to Daily Bread.</li>
          <li>You set up a notification to receive either a text message or an email every day around a certain time.</li>
          <li>Daily Bread sends you a notification every day with your account balances.</li>
        </ol>
      </Typography>
      <Typography component="h2" variant="h5">
        Is it safe? Is it secure?
      </Typography>
      <Typography variant="body1">
        Daily Bread doesn't store any of your financial data! We use
        {' '}
        <a href="https://plaid.com/">Plaid</a>
        {' '}
        to allow you to connect your bank accounts securely, and we use
        {' '}
        <a href="https://www.twilio.com/">Twilio</a>
        {' '}
        to safely send SMS messages to your phone. Still concerned? Check out our
        {' '}
        <MuiLink component={Link} to="/privacy">
          privacy policy
        </MuiLink>
        {' '}
        and
        {' '}
        <MuiLink component={Link} to="/terms">
          terms of use
        </MuiLink>
        .
      </Typography>
      <Typography component="h2" variant="h5">
        How much does it cost?
      </Typography>
      <Typography variant="body1">
        We're just trying to cover our costs while providing a useful service. You can pick an yearly or monthly subscription and pay as little as
        {' '}
        <b>$1.67 per month</b>
        {' '}
        to use Daily Bread. That's pocket change!
      </Typography>
      <Button
        component={Link}
        disableElevation
        startIcon={<Icon>account_circle</Icon>}
        to="/signup"
        variant="contained"
      >
        Sign up
      </Button>
      <Button
        component={Link}
        disableElevation
        fullWidth
        startIcon={<Icon>login</Icon>}
        to="/login"
      >
        Log in
      </Button>
    </Stack>
  )
}
