import {
  Button,
  Icon,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material'
import { Form, Link, redirect, useActionData } from 'react-router-dom'
import { Endpoints, apiPost } from '../lib/api'
import Teaser from './teaser'

export async function signupAction({ request }) {
  const formData = await request.formData()
  const payload = Object.fromEntries(formData)
  const response = await apiPost(Endpoints.AUTH_SIGNUP, payload)

  if (response.status !== 200) {
    const json = await (response.clone()).json()

    if (json?.error?.field) {
      return json.error
    }

    throw response
  }

  return redirect('/')
}

export default function Signup() {
  const error = useActionData()

  if (error) {
    console.error(error)
  }

  return (
    <Stack spacing={4}>
      <Teaser />
      <Form method="post">
        <Stack spacing={2}>
          <TextField
            autoComplete="email"
            error={error?.field === 'email'}
            fullWidth
            helperText={error?.field === 'email' ? error.message : null}
            label="Email"
            name="email"
            required
            type="email"
          />
          <TextField
            autoComplete="name"
            error={error?.field === 'name'}
            fullWidth
            helperText={error?.field === 'name' ? error.message : null}
            label="Name"
            name="name"
            required
          />
          <TextField
            autoComplete="tel-national"
            error={error?.field === 'phone'}
            fullWidth
            helperText={error?.field === 'phone' ? error.message : null}
            InputProps={{
              startAdornment: <InputAdornment position="start">+1</InputAdornment>
            }}
            label="Phone"
            name="phone"
            required
          />
          <TextField
            autoComplete="current-password"
            error={error?.field === 'password'}
            fullWidth
            helperText={error?.field === 'password' ? error.message : null}
            label="Password"
            name="password"
            required
            type="password"
          />
          <Button
            disableElevation
            startIcon={<Icon>account_circle</Icon>}
            type="submit"
            variant="contained"
          >
            Sign up
          </Button>
          <Button
            component={Link}
            disableElevation
            fullWidth
            startIcon={<Icon>login</Icon>}
            to="/login"
          >
            Log in
          </Button>
        </Stack>
      </Form>
    </Stack>
  )
}
