import { Alert, Button, Icon, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export default function Teaser() {
  return (
    <Alert severity="info">
      <Stack spacing={2}>
        <Typography variant="body1">
          Daily Bread sends you your bank account balances every day. That's it.
        </Typography>
        <div>
          <Button
            disableElevation
            component={Link}
            startIcon={<Icon>auto_stories</Icon>}
            to="/about"
            variant="outlined"
          >
            Learn more
          </Button>
        </div>
      </Stack>
    </Alert>
  )
}
