import './App.css'
import { Box, Container, Link as MuiLink, Stack, Typography } from '@mui/material'
import { Link, Outlet, useNavigation } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import LoadingOverlay from './components/loading-overlay'

dayjs.extend(utc)

export default function App() {
  const year = dayjs().year()
  const navigation = useNavigation()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="xs">
        <Box py={4}>
          <Stack spacing={4}>
            <Link className="header" to="/">
              <Typography component="h1" variant="h4" textAlign="center">
                🍞
                <br />
                Daily Bread
              </Typography>
            </Link>
            <Outlet />
            <Typography variant="body2" textAlign="center">
              <MuiLink component={Link} to="/about">
                What is Daily Bread?
              </MuiLink>
              {' • '}
              &copy;
              {' '}
              {year}
              {' '}
              <MuiLink href="https://benfarhner.com">
                Ben Farhner
              </MuiLink>
              <br />
              <MuiLink component={Link} to="/privacy">
                Privacy
              </MuiLink>
              {' • '}
              <MuiLink component={Link} to="/terms">
                Terms
              </MuiLink>
            </Typography>
          </Stack>
        </Box>
      </Container>
      {navigation.state !== 'idle' && <LoadingOverlay />}
    </LocalizationProvider>
  )
}
